
import { 
  IonButton,
  IonButtons,
  IonContent,
  IonCard,
  IonCardContent,
  IonDatetime,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
  useBackButton 
} from '@ionic/vue'
import { 
  computed,
  defineComponent,
  reactive
} from 'vue'
import { refreshOutline, closeOutline, filterOutline } from 'ionicons/icons'

export default defineComponent({
  name: 'ReportFilter',
  components: { 
    IonButton,
    IonButtons,
    IonContent,
    IonCard,
    IonCardContent,
    IonDatetime,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonPage,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar
  },
  props: { 
    formData: {
      type: Object,
      default: () => ({ 
        type: 'income',
        filterData: {
          fromDate: '',
          toDate: '',
          category: []
        },
        categoryLists: {
          income: [],
          expense: []
        },
        formDetail: {}
      })
    }
  },
  setup (props: any, ctx) {

    useBackButton(10, () => {
      alert('Back Button Handler was called!');
    });
    
    const today = new Date();
    
    const formItem = reactive<any>({
      filterFromDate: new Date(today.getFullYear(), today.getMonth(), 1).toISOString(),
      filterToDate: new Date(today.getFullYear(), today.getMonth() + 1, 0).toISOString(),
      filterCategory: []
    })

    const categoryLists: any = {
      income: [],
      expense: []
    }

    const putTitle = computed(() => {
      let result = ''
      switch (props.formData.type) {
        case 'income':
          result = 'Filter Income'
          break
        case 'expense':
          result = 'Filter Expense'
          break
        case 'report':
          result = 'Filter Report'
          break
      }
      Object.assign(categoryLists, { ...props.formData.categoryLists})
      if (props.formData.filterData.fromDate) formItem.filterFromDate = new Date (props.formData.filterData.fromDate).toISOString()
      if (props.formData.filterData.toDate) formItem.filtertoDate = new Date (props.formData.filterData.toDate).toISOString()
      if (props.formData.filterData.category) formItem.filterCategory = props.formData.filterData.category
      // console.log(props)
      // console.log(categoryLists)
      return result
    })

    const submitForm = () => {
      // console.log('submit Income Form')
      // console.log(formItem)
      const filterToDate = new Date(formItem.filterToDate)
      const response = {
        'action': 'filter',
        'type': '',
        'formData': {
          'filterFromDate': new Date (formItem.filterFromDate),
          'filterToDate': new Date (filterToDate.getFullYear(), filterToDate.getMonth(), filterToDate.getDate() + 1),
          'filterCategory': formItem.filterCategory
        }
      }
      // console.log(response)
      ctx.emit('filter', response)
    }

    const closeForm = () => {
      // console.log('close Income Form')
      const response = {
        'action': 'close',
        'formData': null
      }
      // console.log(response)
      ctx.emit('close', response)
    }

    const resetForm = () => {
      const response = {
        'action': 'filter',
        'type': '',
        'formData': {
          'filterFromDate': new Date(today.getFullYear(), today.getMonth(), 1),
          'filterToDate': new Date(today.getFullYear(), today.getMonth() + 1, 0),
          'filterCategory': []
        }
      }
      // console.log(response)
      ctx.emit('filter', response)
    }

    return {
      //method
      submitForm,
      closeForm,
      resetForm,

      //data
      formItem,
      categoryLists,
      putTitle,

      //icon
      refreshOutline,
      closeOutline,
      filterOutline
    }
  }
})
